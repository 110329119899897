
	var $isotopeGrid;
	var answerOwl;
	var sectionCarousel;
	var $isotopeGridBanner;
	
	$(window).on('load', function(event) {
		$(".popup").width($(window).outerWidth());

		//timeago().render($('time.timeago'));

		$(".__select").each(function(index, el) {
			var insertHtml = "";
			insertHtml += '<label for="__' + $(this).attr('name') + '">' + $(this).attr('data-title') + '</label>';
			insertHtml += '<input type="text" name="__' + $(this).attr('name') + '" id="__' + $(this).attr('name') + '" class="form-control form-control-dropdown" readonly>';
            insertHtml += '<ul class="form-control-dropdown-list"></ul>';
            $(this).before(insertHtml);
		});
		$('.number-input.number-input-disabled').each(function(index, el) {
			$(this).attr('disabled', 'disabled');
		});
		$('.form-half-part').each(function(index, el) {
			$(this).height($(this).parent().height());
		});
		$('.big-image').each(function(index, el) {
			var html = '<div class="small-image"><a href="#"><img src="' + $(this).find('img').attr('src') + '" alt=""></a></div>';
			$(".small-images").append(html);
		});
		var demoTrigger = document.querySelector('.demo-trigger');
		$('.big-image').each(function(index, el) {
			new Drift($(this).find('a').get(0), {
			  paneContainer: document.querySelector('.big-image-preview'),
			  inlinePane: true,
			  inlineOffsetY: -85,
			  containInline: true,
			  sourceAttribute: 'href',
			  handleTouch: false
			});
			new Luminous($(this).find('a').get(0));
		});
		$('.nav-tabs a').click(function (e) {
		  e.preventDefault();
		  $(this).tab('show');
		});
		$('.nav-tabs').each(function(){
			if($(this).children().length == 1) {
				$(this).addClass('nav-tabs-single');
			}
		});

		$('.big-images').each(function() {
			$(this).owlCarousel({
				items: 1
			});
		});
		
		$('.small-images').each(function() {
			$(this).owlCarousel({
				items: 3
			});
		});

		if($('.timer_countdown').length) {
			setInterval(function(){

				var d = new Date();
				var n = d.getDay();

				if(n < 6) {
					if(n < 5) { // Mon - Thu
						var one_day=1000*60*60*24;
						// Convert both dates to milliseconds
						var date2_ms = new Date();
						if(d.getHours() < 12) { // Shipment time not yet passed
							date2_ms.setTime(date2_ms.getTime());
						}
						else if(d.getHours() == 12 && d.getMinutes() < 30) { // Shipment time not passed - in twelfth hour
							date2_ms.setTime(date2_ms.getTime());
						}
						else { // Thursday, shipment time passed - add one day
							date2_ms.setTime(date2_ms.getTime() + one_day);
						}
						// Calculate the difference in milliseconds
						date2_ms.setHours(12);
						date2_ms.setMinutes(30);
						date2_ms.setSeconds(1);
						var remDate = getTimeRemaining(date2_ms);
						$('.timer_countdown').html(pad(remDate.days * 24 + remDate.hours, 2) + ":" + pad(remDate.minutes, 2) + ":" + pad(remDate.seconds, 2));
					} else { // Fri
						var one_day=1000*60*60*24;
						// Convert both dates to milliseconds
						var date2_ms = new Date();
						if(d.getHours() < 12) { // Shipment time not yet passed
							date2_ms.setTime(date2_ms.getTime());
						}
						else if(d.getHours() == 12 && d.getMinutes() < 30) { // Shipment time not passed - in twelfth hour
							date2_ms.setTime(date2_ms.getTime());
						}
						else { // Friday, shipment time passed - add three days
							date2_ms.setTime(date2_ms.getTime() + 3 * one_day);
						}
						// Calculate the difference in milliseconds
						date2_ms.setHours(12);
						date2_ms.setMinutes(30);
						date2_ms.setSeconds(1);
						var remDate = getTimeRemaining(date2_ms);
						$('.timer_countdown').html(pad(remDate.days * 24 + remDate.hours, 2) + ":" + pad(remDate.minutes, 2) + ":" + pad(remDate.seconds, 2));
					}
				} else if(n == 6) { // Saturday
					var one_day=1000*60*60*24;
						// Convert both dates to milliseconds
						var date2_ms = new Date();
						if(d.getHours() < 12) { // Shipment time not yet passed
							date2_ms.setTime(date2_ms.getTime());
						}
						else if(d.getHours() == 12 && d.getMinutes() < 30) { // Shipment time not passed - in twelfth hour
							date2_ms.setTime(date2_ms.getTime());
						}
						else { // Saturday, shipment time passed - add two days
							date2_ms.setTime(date2_ms.getTime() + 2 * one_day);
						}
						// Calculate the difference in milliseconds
						date2_ms.setHours(12);
						date2_ms.setMinutes(30);
						date2_ms.setSeconds(1);
						var remDate = getTimeRemaining(date2_ms);
						$('.timer_countdown').html(pad(remDate.days * 24 + remDate.hours, 2) + ":" + pad(remDate.minutes, 2) + ":" + pad(remDate.seconds, 2));
				}
				else if(n == 7) { // Sunday
					var one_day=1000*60*60*24;
						// Convert both dates to milliseconds
						var date2_ms = new Date();
						if(d.getHours() < 12) { // Shipment time not yet passed
							date2_ms.setTime(date2_ms.getTime());
						}
						else if(d.getHours() == 12 && d.getMinutes() < 30) { // Shipment time not passed - in twelfth hour
							date2_ms.setTime(date2_ms.getTime());
						}
						else { // Sunday, shipment time passed - add one day
							date2_ms.setTime(date2_ms.getTime() + one_day);
						}
						// Calculate the difference in milliseconds
						date2_ms.setHours(12);
						date2_ms.setMinutes(30);
						date2_ms.setSeconds(1);
						var remDate = getTimeRemaining(date2_ms);
						$('.timer_countdown').html(pad(remDate.days * 24 + remDate.hours, 2) + ":" + pad(remDate.minutes, 2) + ":" + pad(remDate.seconds, 2));
				}
			}, 1000);
		}

		
		$isotopeGrid = $('.product-category-holder:not(.product-slider)').isotope({
		  itemSelector: '.product-holder',
		  layoutMode: 'fitRows'
		});

		if($('.product-slider').length) {
			var productSlider = $('.product-slider').owlCarousel({
				pagination: false,
				navigation: true,
				addClassActive: true,
				autoHeight: true,
				responsive: {
					0: {
						items: 2
					},
					768: {
						items: 3
					},
					1280: {
						items: 4
					}
				}
			});

			$('.product-slider-controls a').on('click', function(e) {
				e.preventDefault();
				if($(this).hasClass('owl-prev')) {
					productSlider.trigger('prev.owl');
				} else {
					productSlider.trigger('next.owl');
				}
			});
		}

		// Wizard
		$('.wizard-holder').append('<a href="#" class="reset-form"><i class="icon_refresh"></i></div>');
		var questionsLength = $('.wizard .item:not(.wizard-disclaimer):not(.wizard-submit)').length;
		$('.wizard .item:not(.wizard-disclaimer)').each(function(index, el) {
			// Counter config
			$(this).find('.heading').attr('data-counter', (index + 1) + '/' + questionsLength);
		});
		$('.wizard .item:not(.wizard-disclaimer)').each(function(index, el) {
			if(!$(this).hasClass('slider')) {
				var checkBox = '<input onselectstart="return false;" type="checkbox" class="answer-checkbox" name="answer_' + (index+1) + '"/>';
				var toggle = '<div class="answer-toggle"><span class="yes">Yes</span><span class="no">No</span></div>';
				$(this).find('.answer.answer-choice').html(checkBox + toggle);
			}
			else {
				var input = '<input onselectstart="return false;" type="text" class="answer-input" name="answer_' + (index+1) + '"/>';
				$(el).find('.answer').append(input);
				noUiSlider.create($(el).find('.slider-control').get(0) ,{
					start:[0],
					step:1,
					range:{min: [0], max:[10]},
					pips:{
						mode:"range",
						density: 10}
					}),
					$(el).find('.slider-control').get(0).noUiSlider.on("update",function(){
						var sliderValue = $(el).find('.slider-control').get(0).noUiSlider.get().replace('.00', '');
						$(el).find('.slider-amount').html(sliderValue);
						$(el).find('.answer-input').val(sliderValue);
				});
				$(this).find('.heading').attr('data-counter', (index + 1) + '/' + questionsLength);
			}
		});

		//$('.wizard .owl-page').detach(owlPageClick, 'click');
		
		$('.form-component').each(function(index, el) {
			if($(this).hasClass('form-component-dropdown')) {
				$(this).find('.form-control').val($(this).find('.__select option:selected').html());
				$(this).find('.form-control').addClass('active');
			} else {
				if($(this).find('.form-control').val() != '')
					$(this).find('.form-control').addClass('active');
			}
		});
		var ctx = $("#myChart");
		if(ctx.length) {
			var myChart = new Chart(ctx, {
			    type: 'bar',
			    data: {
			        labels: ["Liquorice", "Liquorice Free", "Strong", "Free", "Original", "Menthol"],
			        datasets: [{
			            label: '%',
			            data: [100, 60, 75, 90, 40, 45],
			            backgroundColor: [
			                'rgba(255, 99, 132, 1)',
			                'rgba(54, 162, 235, 1)',
			                'rgba(255, 206, 86, 1)',
			                'rgba(75, 192, 192, 1)',
			                'rgba(153, 102, 255, 1)',
			                'rgba(255, 159, 64, 1)'
			            ],
			            borderColor: [
			                'rgba(255,99,132,1)',
			                'rgba(54, 162, 235, 1)',
			                'rgba(255, 206, 86, 1)',
			                'rgba(75, 192, 192, 1)',
			                'rgba(153, 102, 255, 1)',
			                'rgba(255, 159, 64, 1)'
			            ],
			            borderWidth: 1
			        }]
			    },
			    options: {
			        scales: {
			            yAxes: [{
			                ticks: {
			                    beginAtZero:true
			                }
			            }]
			        }
			    }
			});
		}
	}); // Window Load

	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	  }
  

	function getTimeRemaining(endtime){
		var t = Date.parse(endtime) - Date.parse(new Date());
		var seconds = Math.floor( (t/1000) % 60 );
		var minutes = Math.floor( (t/1000/60) % 60 );
		var hours = Math.floor( (t/(1000*60*60)) % 24 );
		var days = Math.floor( t/(1000*60*60*24) );
		return {
		  'total': t,
		  'days': days,
		  'hours': hours,
		  'minutes': minutes,
		  'seconds': seconds
		};
	  }
  

	var lastScroll = 0;
	$(window).scroll(function(event) {
		if(lastScroll > $(window).scrollTop() && $('.primary-navigation--first-level').hasClass('hide-nav')) {
			$('.primary-navigation--first-level').removeClass('hide-nav');
		} else if(lastScroll < $(window).scrollTop() && !$('.primary-navigation--first-level').hasClass('hide-nav')) {
			$('.primary-navigation--first-level').addClass('hide-nav');
		}
		lastScroll = $(window).scrollTop();
	});

	$('.product-category-filter').on( 'click', 'button', function() {
		$(this).parent().find('button').removeClass('active');
		$(this).addClass('active');
		var filterValue = $(this).attr('data-filter');
		$isotopeGrid.isotope({ filter: filterValue });
	});
	$(window).resize(function(event) {
		if($('.nav_container').hasClass('is-fixed-mobile')) {
			$('body').css('padding-top', $('.nav_container').height() + 'px');
		}
		$(".popup").width($(window).outerWidth());
		var popup = $('.popup');
		popup.each(function(index, el) {
			if($(this).is(':visible')) {
				$(this).css("right", "");
				$(this).css("right", $(this).offset().left + "px");
			}
		});
	});
	$(window).click(function(event) { 
		$('.form-control-dropdown-list').each(function(index, el) {
			if($(this).parent().find('.form-control').val() == "") {
				$(this).parent().find('.form-control').removeClass('active');
			}
		});
		$('.form-control-dropdown-list').hide();
	});
	$('.burger-menu').on('click', function(){
		var popup = $('.popup');
		if(popup.is(':visible')) {
			popup.hide();
		}
		var nav = $('.primary-navigation--first-level');
		nav.toggleClass('active'); 
		$(this).toggleClass('active');
	});
	$('.cookies-banner button.close').on('click', function(){
		$(this).parent().slideUp(500, function(){
			$(window).trigger('resize'); 
		});
		$(window).resize(); 
	});
	$('body').on('click', '.form-control-dropdown', function(event){
		if($(this).parent().find("ul").is(":visible")) {
			event.stopPropagation();
		}
	});
	$('body').on('click', function(event) {
		if($(this).hasClass('dropdown-is-open')) {
			if(!$(event.target).parents('.burger-menu__wrapper').length) {
				$('.popup').hide();
				$('body').removeClass('dropdown-is-open');
			}
		}
	});
	$('body').on('focus', '.form-control', function(){
		if($(this).hasClass('form-control-dropdown')) {
			$(this).parent().find("ul").html("");
			var allhtml = "";
			$(this).parent().find(".__select option").each(function(index, el) {
				allhtml += "<li>";
				allhtml += $(this).text();
				allhtml += "</li>";
			});
			$(this).parent().find("ul").html(allhtml);
			$('.form-control-dropdown-list').each(function(index, el) {
				if($(this).parent().find('.form-control').val() == '')
					$(this).parent().find('.form-control').removeClass('active');
				$(this).hide();
			});
			$(this).parent().find("ul").show();
		}
		$(this).addClass('active');
	}).on('blur', '.form-control', function(){
		if(!$(this).hasClass('form-control-dropdown')) {
			if($(this).val() == "") { 
				$(this).removeClass('active');
			} 
		}
	});

	$('body').on('click', '.form-component-dropdown ul li', function(event){
		event.stopPropagation();
		$(this).parent().parent().find('.form-control').val($(this).html()); 
		var input = $(this); 
		$(this).parent().parent().find('.__select option').removeAttr('selected');
		$(this).parent().parent().find('.__select option').each(function(index, el) {
			if($(this).text() == input.html()) {
				$(this).attr('selected', 'selected'); 
			} else {
				$(this).removeAttr('selected'); 
			}
		}); 
		$(this).parent().hide();
	});

	$('.radio-option input[type="radio"]').click(function(event) {
		if($(this).is(":checked")) {
			$('.add-inputs').slideUp();
			$(this).parents('.radio-option').find('.add-inputs').slideDown();
		}
	});

	$('.btn-coupon').click(function(event){
		event.preventDefault();
		var input = $(this).parent().find('.form-component-coupon');
		if(input.is(':visible')) {
			input.slideUp();
		} else {
			input.slideDown();
		}
	});

	$('[data-modal]').click(function(event) {
		event.preventDefault();
		$($(this).attr('data-modal')).show();
		$('.backdrop').show();
	});

	$('.modal .btn-modal-close').click(function(event) {
		event.preventDefault();
		$(this).parents('.modal').hide();
		$('.backdrop').hide();
	});
	if(!$('body').hasClass('webshop')) {
		$('.btn-confirm').click(function(event) {
			event.preventDefault();
			if($('#terms').is(':checked')) {
				var btn = $(this);
				btn.addClass('loading');
				setTimeout(function(){
					btn.removeClass('loading');
					notie.alert(1, 'Success!', 2);
					setTimeout(function(){
						window.location.href = "checkout_complete.html";
					}, 1000);
				}, 3500);
			} else {
				notie.alert(3, 'You must agree to our terms first!', 2);
			}
		});
	
		$('.apply-coupon').click(function(event) {
			event.preventDefault();
			var code = $(this).parent().find('.form-control').val();
			if(code != '')
				notie.alert(1, 'Discount code applied: ' + code, 2);
		});
		$("nav.nav-top .dropdown").on("show.bs.dropdown", function(event){
			if($(window).width() < 671) {
				var dd = $(event.relatedTarget).parent().find('.dropdown-menu');
				setTimeout(function() {
					dd.css({
						left: 0 + "px",
					});
					dd.css({
						left: "-" + dd.offset().left + "px",
						width: $(window).width() + "px" 
					});
				}, 10);
			}
		});
		$('.btn-add-to-cart').click(function(event) {
			event.preventDefault();
			if($(this).parents('.detail').length) {
				if($(this).parents('.detail').find('#__variant').val() != "") {
					notie.alert(1, 'Item added. <a href="#">Go to cart.</a>', 2);
				} else {
					notie.alert(2, 'Choose variant.', 2);
				}
			}
			notie.alert(1, 'Item added. <a href="#">Go to cart.</a>', 2);
		});
		$("body").on('click', '.number-input-control', function(event) {
			event.preventDefault();
			var increase = $(this).hasClass('number-input-higher');
			try {
				var num = parseInt($(this).parent().find('.number-input').val());
				if(increase) {
					num++;
				} else {
					if(num > 0)
						num--;
				}
				$(this).parent().find('.number-input').val(num.toString());
	
			} catch(exception) {
	
			}
		});
		$('.number-input:not(.number-input-disabled)').each(function(index, el) {
			$(this).before('<button class="number-input-control number-input-lower"><i class="icon arrow_carrot-down"></i></button>');	
			$(this).after('<button href="#" class="number-input-control number-input-higher"><i class="icon arrow_carrot-up"></i></button>');	
		});
	}


	$('.backdrop').click(function(event) {
		$('.modal').hide();
		$(this).hide();
	});

	$("body").on('click', '.small-image a', function(event) {
		event.preventDefault();
		var owl = $('.big-images').owlCarousel();
		owl.trigger('to.owl', $(this).parent().parent().index());
	});

	$("body").on('click', '.answer-toggle .yes, .wizard-disclaimer button, .wizard .item.slider button', function(event) {
		if($(this).parent().hasClass('disclaimer-button') || $(this).parent().parent().parent().hasClass('slider')) {
			event.preventDefault();
			if($(this).parent().parent().hasClass('unanswered')) {
				setTimeout(function(){
					answerOwl.next();
				}, 600);
			}
			$(this).parent().parent().removeClass('unanswered');
			adjustBar($(this), true);
		} else {
			event.preventDefault();
			$(this).parent().addClass('active');
			$(this).parent().parent().find('.answer-checkbox').prop('checked', true);
			if($(this).parent().parent().hasClass('unanswered')) {
				setTimeout(function(){
					answerOwl.next();
				}, 800);
			}
			$(this).parent().parent().removeClass('unanswered');
			adjustBar($(this), true);
		}
	});
	$("body").on('click', '.answer-toggle .no', function(event) {
		event.preventDefault();
		$(this).parent().removeClass('active');
		$(this).parent().parent().find('.answer-checkbox').prop('checked', false);
		if($(this).parent().parent().hasClass('unanswered')) {
			setTimeout(function(){
				answerOwl.next();
			}, 800);
		}
		$(this).parent().parent().removeClass('unanswered');
		adjustBar($(this), true);
	});
	function adjustBar(el, delay) {
		if(delay) {
			setTimeout(function(){
				el.parents('.wizard-holder').find('.bar').css('width', ((el.parents('.wizard-holder').find('.owl-item.active').index()) / (el.parents('.wizard-holder').find('.owl-item').length - 1)) * 100 + '%');
			}, 900);
		}
		else {
			el.parents('.wizard-holder').find('.bar').css('width', ((el.parents('.wizard-holder').find('.owl-item.active').index()) / (el.parents('.wizard-holder').find('.owl-item').length - 1)) * 100 + '%');
		}
	}
	$('body').on('click', '.reset-form', function(event) {
		event.preventDefault();
		answerOwl.goTo(1);
		$(this).parent().find('.answer').addClass('unanswered');
		adjustBar($(this));
	});

	$('.main-footer__newsletter .btn').click(function(event) {
		event.preventDefault();
		if(validateEmail($(this).parent().find('input').val())) {
			notie.alert(1, 'You\'ve been added to our newsletter list!', 2);
		}
		else { 
			notie.alert(2, 'Invalid email.', 2);
		}
	});
	var shaveText;
	if ($('.btn-shave').length) {
        shaveText = $('.btn-shave').prev('div').html();
        shave('.shave-div', 72);
    };

    $('.btn-shave').click(function (event) {
        event.preventDefault();
        if ($(this).prev('div').find('.js-shave').length) {
            $(this).html($(this).attr('data-shave-text'));
            $(this).prev('div').html(shaveText);
            $(this).prev('div').find('.js-shave, .js-shave-char').remove();
        } else {
            shaveText = $('.btn-shave').prev('div').html();
			shave('.shave-div', 72);
			$(this).html($(this).attr('data-unshave-text'));
        }

    });

	function validateEmail(email) {
	    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(email);
	}

	$('.submit-holder .btn').click(function(event) {
		event.preventDefault();
		var modalName = $(this).attr('data-modal');
		if ( jQuery('input[name=answer_1]').prop('checked') ) {
				free = 10;
				original = 14;
				light = 11;
				lakrids = 15;
				free_menthol = 5;
				menthol = 10;
				free_lakrids = 5;
				strong = 20;

			//Nej til spørgsmål 1
			} else {
				free = 16;
				original = 10;
				light = 15;
				lakrids = 10;
				free_menthol = 15;
				menthol = 14;
				free_lakrids = 16;
				strong = 10;
			}

		//Spørgsmål 2
			//Ja til spørgsmål 2
			if ( jQuery('input[name=answer_2]').prop('checked') ) {
				free = free + 10;
				original = original + 10;
				light = light + 26;
				lakrids = lakrids + 5;
				free_menthol = free_menthol + 5;
				menthol = menthol + 5;
				free_lakrids = free_lakrids + 5;
				strong = strong + 5;

			//Nej til spørgsmål 2
			} else {
				free = free + 15;
				original = original + 15;
				light = light + 10;
				lakrids = lakrids + 15;
				free_menthol = free_menthol + 15;
				menthol = menthol + 15;
				free_lakrids = free_lakrids + 15;
				strong = strong + 15;
			}

		//Spørgsmål 3
			//Ja til spørgsmål 3
			if ( jQuery('input[name=answer_3]').prop('checked') ) {
				free = free + 5;
				original = original + 10;
				light = light + 5;
				lakrids = lakrids + 10;
				free_menthol = free_menthol + 5;
				menthol = menthol + 5;
				free_lakrids = free_lakrids + 5;
				strong = strong + 25;

			//Nej til spørgsmål 3
			} else {
				free = free + 15;
				original = original + 15;
				light = light + 15;
				lakrids = lakrids + 15;
				free_menthol = free_menthol + 10;
				menthol = menthol + 10;
				free_lakrids = free_lakrids + 10;
				strong = strong + 10;
			}

		//Spørgsmål 4
			//Ja til spørgsmål 4
			if ( jQuery('input[name=answer_4]').prop('checked') ) {
				free = free + 5;
				original = original + 15;
				light = light + 10;
				lakrids = lakrids + 5;
				free_menthol = free_menthol + 26;
				menthol = menthol + 25;
				free_lakrids = free_lakrids + 10;
				strong = strong + 5;

			//Nej til spørgsmål 4
			} else {
				free = free + 10;
				original = original + 15;
				light = light + 10;
				lakrids = lakrids + 10;
				free_menthol = free_menthol + 10;
				menthol = menthol + 10;
				free_lakrids = free_lakrids + 10;
				strong = strong + 10;
			}

		//Spørgsmål 5
			//Ja til spørgsmål 5
			if ( jQuery('input[name=answer_5]').prop('checked') ) {
				free = free + 0;
				original = original + 5;
				light = light + 5;
				lakrids = lakrids + 15;
				free_menthol = free_menthol + 5;
				menthol = menthol + 5;
				free_lakrids = free_lakrids + 10;
				strong = strong + 26;

			//Nej til spørgsmål 5
			} else {
				free = free + 10;
				original = original + 10;
				light = light + 10;
				lakrids = lakrids + 10;
				free_menthol = free_menthol + 10;
				menthol = menthol + 10;
				free_lakrids = free_lakrids + 5;
				strong = strong + 10;
			}

		//Spørgsmål 6
			//Ja til spørgsmål 6
			if ( jQuery('input[name=answer_6]').prop('checked') ) {
				free = free + 5;
				original = original + 5;
				light = light + 5;
				lakrids = lakrids + 16;
				free_menthol = free_menthol + 5;
				menthol = menthol + 5;
				free_lakrids = free_lakrids + 25;
				strong = strong + 5;

			//Nej til spørgsmål 6
			} else {
				free = free + 10;
				original = original + 10;
				light = light + 10;
				lakrids = lakrids + 10;
				free_menthol = free_menthol + 10;
				menthol = menthol + 10;
				free_lakrids = free_lakrids + 10;
				strong = strong + 10;
			}

		//Spørgsmål 7
			//Ja til spørgsmål 7
			if ( jQuery('input[name=answer_7]').prop('checked') ) {
				free = free + 20;
				original = original + 17;
				light = light + 10;
				lakrids = lakrids + 10;
				free_menthol = free_menthol + 20;
				menthol = menthol + 15;
				free_lakrids = free_lakrids + 20;
				strong = strong + 0;

			//Nej til spørgsmål 7
			} else {
				free = free + 10;
				original = original + 20;
				light = light + 20;
				lakrids = lakrids + 15;
				free_menthol = free_menthol + 5;
				menthol = menthol + 15;
				free_lakrids = free_lakrids + 10;
				strong = strong + 10;
			}
			var returnArray = [];
			returnArray[0] = free;
			returnArray[1] = original;
			returnArray[2] = light;
			returnArray[3] = lakrids;
			returnArray[4] = free_menthol;
			returnArray[5] = menthol;
			returnArray[6] = free_lakrids;
			returnArray[7] = strong;
			var cigs = [
				['XSmoke® Free','img/result1.jpg' , '#' ],
				['XSmoke® Original','img/result2.jpg' , '#' ],
				['XSmoke® Light','img/result3.jpg' , '#' ],
				['XSmoke® Lakrids','img/result4.jpg' , '#' ],
				['XSmoke® Free Menthol','img/result5.jpg' , '#' ],
				['XSmoke® Menthol','img/result6.jpg' , '#' ],
				['XSmoke® Free Lakrids','img/result7.jpg' , '#' ],
				['XSmoke® Strong','img/result8.jpg' , '#' ]
			];
			var i = 0, highestValue = 0, number = 0;
			for(i = 0; i < returnArray.length; i++) {
				if(highestValue < returnArray[i]) {
					highestValue = returnArray[i];
					number = i;
				}
			}
			event.preventDefault();
			$(modalName).show();
			$(modalName + " .product-title").html(cigs[number][0]);
			$(modalName + " .product-img").attr('src', cigs[number][1]);
			$('.backdrop').show();
	});