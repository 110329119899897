// Variables
var owlTestimonial, owlSeo;

$(window).on('load', function() {
	mainProductImagePoints();
	productScrollInit();
	owlInit();
	collageInit();
	navInit();
});

function mainProductImagePoints() {
	var imgW = $('.main-product .main-image').width();
	var imgH = $('.main-product .main-image').height();
	var imgX = $('.main-product').width() / 2 - imgW / 2;
	var point1 = [40, 6];
	var point2 = [60, 6];
	var point3 = [50, 10];
	var point4 = [50, 30];
	var point5 = [50, 50];
	var pos = [point1, point2, point3, point4, point5];
	var snap = Snap("#line-holder");
	$('.point-holder span').each(function(index) {
		var pointX = pos[index][0] / 100 * imgW + imgX - 10;
		var pointY = pos[index][1] / 100 * imgH;
		$(this).css({
			'left': pointX + "px",
			'top': pointY + "px"
		})
		var startX = $($('.info-holder .info').get(index)).find('span').offset().left - $('.main-product').offset().left;
		var startY = $($('.info-holder .info').get(index)).find('span').offset().top - $('.main-product').offset().top;
		// var line = snap.polyline(startX, startY, pointX + 10, startY, pointX + 10, pointY + 10);
		var line = snap.line(startX, startY - 20, pointX + 10, pointY + 10);
		line.attr({
			stroke: "#eaeaea",
			fill: "transparent",
			strokeWidth: 3
		});
		line.attr("data-count", index + 1);

		var scrollTrigger = "<div class='scroll-trigger' style='top: " + (index + 1) * 10 + "%" + "' data-count='" + index + "'></div>";
		$('.main-product-holder .scroll-triggers').append(scrollTrigger
			);
	});
}

function productScrollInit() {
	if($('.main-product-holder').length) {
		var controller = new ScrollMagic.Controller();
		new ScrollMagic.Scene({triggerElement: "header", duration: $('header').height()})
						// animate color and top border in relation to scroll position
						.setTween("header", {opacity: "0"})
						.triggerHook(0)
						.addTo(controller);
	
	
		new ScrollMagic.Scene({triggerElement: ".main-product-holder", duration:1300})
						.setPin('.main-product-container')
						.triggerHook(0)
						.setClassToggle('nav.nav-top, .main-product-holder', 'product-showing')
						.addTo(controller);
	
		$('.main-product .info-holder .info').each(function(index) {
			var indexN = index + 1;
			var elements = ".info-holder .info[data-count='" + indexN + "'], #line-holder line[data-count='" + indexN + "'], .point-holder span[data-count='" + indexN + "']";
			new ScrollMagic.Scene({triggerElement: ".scroll-triggers .scroll-trigger[data-count='" + index + "']"})
						.triggerHook(0)
						.setClassToggle(elements, "active")
						.addTo(controller);
			if(index != 0) {
				var elementsN = ".info-holder .info[data-count='" + index + "'], #line-holder line[data-count='" + index + "'], .point-holder span[data-count='" + index + "']";
				new ScrollMagic.Scene({triggerElement: ".scroll-triggers .scroll-trigger[data-count='" + index + "']"})
					.triggerHook(0)
					.setClassToggle(elementsN, "info-hidden")
					.addTo(controller);
			}
		});
	}
}

function owlInit() {
	owlTestimonial = $('.owl-testimonials').each(function() {
		$(this).owlCarousel({
			items: 1,
			onChanged: owlTestimonialChanged,
			onUnitialized: owlTestimonialInit,
			autoHeight: true
		});
	});
	$('.testimonials .owl-controls a').on('click', function(e) {
		e.preventDefault();
		if($(this).hasClass('owl-prev'))
			owlTestimonial.trigger('prev.owl');
		else
			owlTestimonial.trigger('next.owl');
	});
	owlSeo = $('.owl-seo').each(function() {
		$(this).owlCarousel({
			items: 1,
			autoHeight: true
		});
	});
	$('.seo .owl-controls a').on('click', function(e) {
		e.preventDefault();
		if($(this).hasClass('owl-prev'))
			owlSeo.trigger('prev.owl');
		else
			owlSeo.trigger('next.owl');
	});
}

function owlTestimonialChanged(e) {
	$('.testimonials .owl-page').each(function() {
		var index = e.page.index + 1;
		if(index != 0)
			$(this).find('.current').html(index);
	});
}

function owlTestimonialInit(e) {
	$('.testimonials .owl-page').each(function() {
		var count = e.page.count;
		$(this).find('.count').html(count);
	});
}

function collageInit() {
	updateCollage();
	$(window).on('resize', updateCollage);
	$('.collage-container').isotope({
		itemSelector: '.collage-item',
		layoutMode: 'masonry',
		columnWidth: $('.collage-item').get(0)
	});
	(function () {
		var youtube = document.querySelectorAll(".youtube");

		for (var i = 0; i < youtube.length; i++) {
			var source = "//img.youtube.com/vi/" + youtube[i].dataset.embed + "/sddefault.jpg";
			$(youtube[i]).css({
				'background': 'url(' + source + ') center center no-repeat',
				'background-size': 'cover'
			});
			$(youtube[i]).find('.play-button').on('click', function () {
				var parent = $(this).parent();
				var iframe = document.createElement("iframe");
				iframe.setAttribute("frameborder", "0");
				iframe.setAttribute("allowfullscreen", "");
				iframe.setAttribute("src", "https://www.youtube.com/embed/" + parent.attr('data-embed') + "?rel=0&showinfo=0&autoplay=1");
				parent.html("");
				parent.append(iframe);
			});
		};
	})();
}

function updateCollage() {
	var extra = 0;
	$('.collage-container .collage-item').each(function() {
		if($(window).width() < 1069) {
			extra = 0;
		}
		if($(this).hasClass('collage-w1')) {
			if($(this).hasClass('collage-h1'))
				$(this).height($(this).width());
			else
				$(this).height($(this).width() + extra);			
		}
		if($(this).hasClass('collage-w2')) {
			if($(this).hasClass('collage-h1'))
				$(this).height($(this).width() / 2);
			else
				$(this).height($(this).width() / 2 + extra - 15);			
		}
	});
}

function navInit() {
	$('.navigation-toggle').on('click', function(e) {
		e.preventDefault();
		$('nav.nav-top').toggleClass('nav-show');
	});
	$(window).on('scroll', function() {
		var st = $(window).scrollTop();
		if(st > 0) {
			$('nav').addClass('scrolled').removeClass('nav-show');
		} else {
			$('nav').removeClass('scrolled');			
		}
	});
	$('body').css('padding-top', $('nav.nav-top').height() + 'px');
}